<template>
  <v-app>
    <v-navigation-drawer v-if="$vuetify.breakpoint.smAndUp" :mini-variant="true" app color="primary" permanent>
      <v-list color="white">
        <v-list-item>
          <v-list-item-action>
            <v-icon color="black" @click.stop="$router.push({name: 'EReport'})">mdi-arrow-left</v-icon>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <v-main style="height: 100%">
      <div style="position: relative; height: 100%; width: 100%;">
        <router-view></router-view>
        <notification-list></notification-list>
      </div>
    </v-main>
    <v-footer v-if="$vuetify.breakpoint.smAndUp" app class="primary white--text justify-center"
              style="font-weight: 300; font-size: 14px;">
      <span style="letter-spacing: 1px;">2021 -- DIW-POMS</span>
    </v-footer>
  </v-app>
</template>

<script>
import NotificationList from '../components/notificationList'

export default {
  name: 'ReportView',
  components: {
    NotificationList
  }
}
</script>

<style scoped>

</style>
